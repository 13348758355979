import React, { useEffect, useState } from "react"
import styled from 'styled-components';
import { useForm } from 'react-hook-form';
import Section from "components/Section/Section";
import HtmlParser from "utils/HtmlParser/HtmlParser";
import { Input, SubmitButton } from "components/Form/Form";

import { Link } from "gatsby"
import { fontstack } from 'utils/fontstack';
import { type } from 'utils/type';
import { media } from "utils/mediaQuery";
import { encodeToString } from 'utils/encodeToString';

import Layout from "components/Layout/Layout"
import Seo from "../components/seo"

const SubmitLayout = styled(Layout)`
  background: rgb(var(--theme-black));
`

const SubmitSection = styled(Section)` 
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  column-gap: 16px;
  margin: 48px 0 0;

  ${media.small` 
    grid-template-columns: repeat(24, 1fr);
  `}
`

const MainColumn = styled.div` 
  color: rgb(var(--theme-primary));
  grid-column: 1 / span 6;

  ${media.small`
    grid-column: 1 / span 12;
  `}

  ${media.medium`
    grid-column: 1 / span 12;
  `}

  ${media.large`
    grid-column: 9 / span 8;
  `}

  ${media.xlarge`
    grid-column: 9 / span 6;
  `}
`

const Warning = styled.p` 
  ${fontstack.default}
  ${type('body')} 
  color: rgba(var(--theme-primary), 0.4);
  margin: 48px 0 0;
`

const FormGrid = styled.form` 
  margin: 48px 0;
  display: grid;
  column-gap: 16px;
  grid-template-columns: repeat(1, 1fr);
  grid-column: 1 / span 6;

  ${media.small`
    grid-template-columns: repeat(1, 1fr);
    grid-column: 1 / span 12;
  `}

  ${media.medium`
    grid-template-columns: repeat(2, 1fr);
    grid-column: 1 / span 24;
  `}

  ${media.large`
    grid-template-columns: repeat(1, 1fr);
    grid-column: 9 / span 8;
  `}
  
  ${media.xlarge`
    grid-template-columns: repeat(2, 1fr);
    grid-column: 9 / span 12;
  `}
`


const SubmitPage = () => {
  const { register, handleSubmit, reset, setValue, formState: { errors } } = useForm();
  const [ SubmitError, setSubmitError ] = useState();
  const [ SubmitSuccess, setSubmitSuccess ] = useState();

  useEffect(() => {
    document.body.setAttribute('data-theme', 'dark');
    return () => {
      document.body.setAttribute('data-theme', 'light');
    }
  }, [])

  const onSubmit = (data) => {
    const body = encodeToString({
      "form-name": "submit-form",
      ...data,
    });

    fetch("/submit", {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: body,
    })
      .then(() => {
        setSubmitSuccess(true);
        reset();
        setTimeout(()=>setSubmitSuccess(false),5000);
      })
      .catch((error) => {
        setSubmitError('Error, please review the form.')
      });
  }

  return (
    <SubmitLayout omitFilter activeTag="submit">
      <Seo title="Submit" />
      <SubmitSection>
        <MainColumn>
          <HtmlParser>
          {'<p>If you’d like to submit an item to the archive, please fill out the form below or tag us on Instagram <a insta="true" href="https://ig.me/m/seensounds" target="_blank">@seensounds</a>.</p>'}
          </HtmlParser>
        </MainColumn>
        <FormGrid onSubmit={handleSubmit(onSubmit)} data-netlify="true" netlify-honeypot="bot-field" name="submit-form" method="POST">
          <Input label="Artist" placeholder="e.g. Kendrick Lamar" type="text" error={errors.artist ? errors.artist.message : false} {...register('artist',{required: 'Please enter artist name.'})}/>
          <Input label="Project" placeholder="e.g. DAMN" type="text" error={errors.project ? errors.project.message : false} {...register('project',{required: 'Please enter project name.'})}/>
          <Input label="URL" placeholder="e.g. Link to project" type="text" error={errors.url ? errors.url.message : false} {...register('url',{required: 'Please enter the URL to the project.'})}/>
          <Input label="Credits" sublabel="Optional" placeholder="If you know who made this" type="text" {...register('credits')}/>
          <Input label="Instagram Username" sublabel="Optional" placeholder="Used to credit you if posted" type="text" {...register('instagram')}/>
          <SubmitButton success={SubmitSuccess} error={SubmitError}>{SubmitSuccess ? "Submitted" : "Submit"}</SubmitButton>
          <input type="hidden" name="form-name" value="submit-form" />
        </FormGrid>
      </SubmitSection>
    </SubmitLayout>
  )
}

export default SubmitPage
