/**
 * Formats data object from form submission to a url encoded string.
 * @param {object} data The ISO currency code
 * @returns
 */
export const encodeToString = (data) => {
  return Object.keys(data)
  .map(
    (key) =>
      encodeURIComponent(key) + "=" + encodeURIComponent(data[key])
  )
  .join("&");
}
