import React from 'react';
import styled from 'styled-components';
import { fontstack } from 'utils/fontstack';
import { type } from 'utils/type';
import CheckIcon from './assets/success.svg';

const Wrapper = styled.div` 
  margin: 28px 0 0;
`

const SubmitWrapper = styled.button` 
  position: relative;
  ${fontstack.default}
  ${type('body')}
  color: rgb(var(--theme-primary));
  border: 1px solid rgb(var(--theme-primary));
  background: transparent;
  padding: 13px 15px;
  display: block;
  width: 100%;
  border-radius: 2px;
  margin: 0 0 8px;
  cursor: pointer;

  &:hover {

    ${({success}) => !success && `
      color: rgb(var(--theme-secondary));
      background: rgb(var(--theme-primary));
    `}
    
  }

  ${({success}) => success && `
    color: rgb(var(--theme-white));
    border: 1px solid rgb(var(--theme-positive));
    background: rgb(var(--theme-positive));
  `}
`

const BottomLabel = styled.div` 
  position: relative;
  min-height: 20px;
`

const ErrorLabel = styled.div`
  ${fontstack.default}
  ${type('body')}
  color: rgb(var(--theme-negative));
`

const SuccessIcon = styled(CheckIcon)` 
  position: relative;
  & > path {
    fill: rgb(var(--theme-white));
  }
`

const SuccessIconWrapper = styled.div`
  position: absolute;
  top: 0;
  right: 15px;
  height: 100%;
  display: flex;
  align-items: center;
`

const SubmitButton = ({children, error, success, ...rest}) => {
  return (
    <Wrapper>
      <SubmitWrapper type='submit' success={success} {...rest}>{children}{success ? <SuccessIconWrapper><SuccessIcon/></SuccessIconWrapper> : null }</SubmitWrapper>
      <BottomLabel>
        {error ? <ErrorLabel>{error}</ErrorLabel> : null}
      </BottomLabel>
    </Wrapper>
  )
}

export default SubmitButton;