import React from 'react';
import styled from 'styled-components';
import hexRgb from 'hex-rgb';
import { fontstack } from 'utils/fontstack';
import { type } from 'utils/type';
import ExclamationIcon from './assets/error.svg';

const InputEl = styled.input`
  ${fontstack.default}
  ${type('body')}
  color: rgb(var(--theme-primary));
  padding: 13px 15px;
  border-radius: 2px;
  outline: none;
  border: 1px solid rgb(var(--theme-dark-grey));
  box-sizing: border-box;
  width: 100%;
  background: transparent;

  ${({error}) => error && `
    border: 1px solid rgb(var(--theme-negative));
  `}

  &:hover {
    &:not(:focus) {
      ${({error}) => !error && `
        border: 1px solid rgb(var(--theme-grey));
      `}
    }
  }

  &:focus {
    ${({error}) => !error && `
      border-bottom: 1px solid rgb(var(--theme-primary));
    `}
  }

  &::placeholder {
    color: rgb(var(--theme-primary));
    opacity: 0.2;
  }
`

const InputWrapper = styled.div`
  position: relative;
  width: 100%;
  margin: 0 0 8px;
`

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  ${fontstack.default}
  ${type('body')}
  color: rgb(var(--theme-primary));
`

const LabelWrapper = styled.div`
  display: flex;
  margin: 0 0 8px;
`

const Label = styled.label`
  
`

const SubLabel = styled.div` 
  color: rgba(var(--theme-primary),0.4);
  margin: 0 0 0 8px;
`

const BottomLabel = styled.div` 
  position: relative;
  min-height: 20px;
`

const ErrorLabel = styled.div`
  ${fontstack.default}
  ${type('body')}
  color: rgb(var(--theme-negative));
`

const ErrorIcon = styled(ExclamationIcon)` 
  position: absolute;
  right: 15px;
  top: 25%;
  & > path {
    fill: rgb(var(--theme-negative));
  }
`

const Input = React.forwardRef((props, ref) => {
  const { id, label, sublabel, error, ...rest } = props;
  
  return (
    <Wrapper error={error}>
      <LabelWrapper>
        <Label htmlFor={id}>{label}</Label>
        { sublabel ? <SubLabel>{sublabel}</SubLabel> : null}
      </LabelWrapper>
      <InputWrapper>
        <InputEl id={id} {...rest} error={error} ref={ref}/>
        { error ? <ErrorIcon /> : null }
      </InputWrapper>
      <BottomLabel>
        {error ? <ErrorLabel>{error}</ErrorLabel> : null}
      </BottomLabel>
    </Wrapper>
  )
})

export default Input;